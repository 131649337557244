<template>
  <table  >
    <tr style="background-color: #d1d5db; text-align: center">
      <th style="width: 5%">STT</th>
      <th style="width: 40%">Tên khoản thu</th>
      <th style="width: 5%">SL</th>
      <th style="width: 15%">Đơn giá</th>
      <th style="width: 15%">Giảm giá</th>
      <th style="width: 20%">Thành tiền(VNĐ)</th>
    </tr>
    <tr v-for="(item, index) in listData" :key="index">
      <td>{{index+1}}</td>
      <td style="text-align: left">{{ item.name }}</td>
      <td >{{item.quantity}}</td>
      <td style="text-align: right">
        {{ item.price | formatCurrency}}
      </td>
      <td style="text-align: right">{{ item.discount | formatCurrency}}</td>
      <td style="text-align: right">{{ item.sumMoney | formatCurrency}}</td>
    </tr>
    <tr>
      <td colspan="6" style="background-color: #d1d5db;">
        <table style="width: 100%;">
          <tr>
            <td style="text-align: center; font-weight: bold; width: 25%;">Tổng cộng</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.beforeMoney >= 0">Tiền thừa tháng trước</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.beforeMoney < 0">Tiền thiếu tháng trước</td>
            <td style="text-align: center; font-weight: bold; width: 25%;">Số tiền đã trả</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.afterMoney < 0">Số tiền còn thiếu</td>
            <td style="text-align: center; font-weight: bold; width: 25%;" v-if="tableData.afterMoney >= 0">Số tiền còn thừa</td>
          </tr>
          <tr>
            <td style="text-align: right; width: 25%;"><b>{{ tableData.sumMoney | formatCurrencyNew }}</b></td>
            <td style="text-align: right; width: 25%;">{{ tableData.beforeMoney | formatCurrency }}</td>
            <td style="text-align: right; width: 25%;">{{ tableData.collectedMoney | formatCurrency }}</td>
            <td style="text-align: right; width: 25%; color: red;">{{ tableData.afterMoney | formatCurrency }}</td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: left; font-style: italic;">
              <i>Số tiền bằng chữ: {{ tableData.readMoney }}</i>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

</template>
<script>
export default {
  name: 'printNotNullTable',
  props: {
    tableData: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      listData: [],
    }
  },
  mounted() {
    this.getListData()
  },
  methods:{
    getListData(){
      for (let i = 1; i <= 20; i++) {
        if (this.tableData[`nameKt${i}`] !== `Khoản thu ${i}` &&
            (this.tableData[`moneyKt${i}`] !== 0 || this.tableData[`discountKt${i}`] !== 0)
        ) {
          let student = {};
          student.name = this.tableData[`nameKt${i}`];
          student.quantity = this.tableData[`quantityKt${i}`];
          student.price = this.tableData[`priceKt${i}`];
          student.discount = this.tableData[`discountKt${i}`];
          student.sumMoney = this.tableData[`moneyKt${i}`];
          this.listData.push(student);
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}
</style>