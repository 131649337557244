var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    [
      _vm._m(0),
      _vm._l(_vm.listData, function (item, index) {
        return _c("tr", { key: index }, [
          _c("td", [_vm._v(_vm._s(index + 1))]),
          _c("td", { staticStyle: { "text-align": "left" } }, [
            _vm._v(_vm._s(item.name)),
          ]),
          _c("td", [_vm._v(_vm._s(item.quantity))]),
          _c("td", { staticStyle: { "text-align": "right" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("formatCurrency")(item.price)) +
                "\n    "
            ),
          ]),
          _c("td", { staticStyle: { "text-align": "right" } }, [
            _vm._v(_vm._s(_vm._f("formatCurrency")(item.discount))),
          ]),
          _c("td", { staticStyle: { "text-align": "right" } }, [
            _vm._v(_vm._s(_vm._f("formatCurrency")(item.sumMoney))),
          ]),
        ])
      }),
      _c("tr", [
        _c(
          "td",
          {
            staticStyle: { "background-color": "#d1d5db" },
            attrs: { colspan: "6" },
          },
          [
            _c("table", { staticStyle: { width: "100%" } }, [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "bold",
                      width: "25%",
                    },
                  },
                  [_vm._v("Tổng cộng")]
                ),
                _vm.tableData.beforeMoney >= 0
                  ? _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-weight": "bold",
                          width: "25%",
                        },
                      },
                      [_vm._v("Tiền thừa tháng trước")]
                    )
                  : _vm._e(),
                _vm.tableData.beforeMoney < 0
                  ? _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-weight": "bold",
                          width: "25%",
                        },
                      },
                      [_vm._v("Tiền thiếu tháng trước")]
                    )
                  : _vm._e(),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "bold",
                      width: "25%",
                    },
                  },
                  [_vm._v("Số tiền đã trả")]
                ),
                _vm.tableData.afterMoney < 0
                  ? _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-weight": "bold",
                          width: "25%",
                        },
                      },
                      [_vm._v("Số tiền còn thiếu")]
                    )
                  : _vm._e(),
                _vm.tableData.afterMoney >= 0
                  ? _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-weight": "bold",
                          width: "25%",
                        },
                      },
                      [_vm._v("Số tiền còn thừa")]
                    )
                  : _vm._e(),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticStyle: { "text-align": "right", width: "25%" } },
                  [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatCurrencyNew")(_vm.tableData.sumMoney)
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "td",
                  { staticStyle: { "text-align": "right", width: "25%" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency")(_vm.tableData.beforeMoney)
                      )
                    ),
                  ]
                ),
                _c(
                  "td",
                  { staticStyle: { "text-align": "right", width: "25%" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency")(_vm.tableData.collectedMoney)
                      )
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "right",
                      width: "25%",
                      color: "red",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("formatCurrency")(_vm.tableData.afterMoney))
                    ),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "left",
                      "font-style": "italic",
                    },
                    attrs: { colspan: "4" },
                  },
                  [
                    _c("i", [
                      _vm._v(
                        "Số tiền bằng chữ: " + _vm._s(_vm.tableData.readMoney)
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "tr",
      {
        staticStyle: { "background-color": "#d1d5db", "text-align": "center" },
      },
      [
        _c("th", { staticStyle: { width: "5%" } }, [_vm._v("STT")]),
        _c("th", { staticStyle: { width: "40%" } }, [_vm._v("Tên khoản thu")]),
        _c("th", { staticStyle: { width: "5%" } }, [_vm._v("SL")]),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Đơn giá")]),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Giảm giá")]),
        _c("th", { staticStyle: { width: "20%" } }, [
          _vm._v("Thành tiền(VNĐ)"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }